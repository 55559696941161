import { useEffect, useState } from "react";
import Select from "react-select";
import "./ReactSelect.css";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "100%",
    position: "absolute",
    overflowY: "auto",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 100000 }),
  option: (provided) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    textAlign: "center",
    fontFamily: "font-family: 'Roboto', sans-serif;",
    padding: 10,
  }),
  control: () => ({
    border: "1px solid #c0c0c0",
    background: "#fff",
    display: "flex",
    borderRadius: "5px",
    width: "100%",
    cursor: "pointer",
    paddingTop: "2px",
    paddingBottom: "2px",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

const ReactSelect = ({
  data,
  setValue,
  defaultValue,
  isSearchable,
  placeholder,
  isDisabled,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (defaultValue !== undefined) {
      const defaultOption = data?.find(
        (option) => option.value === defaultValue
      );
      setSelectedOption(defaultOption);
    }
  }, [defaultValue, data]);

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setValue(selectedOption.value);
  };

  return (
    <Select
      options={data}
      placeholder={placeholder || "Select..."}
      value={selectedOption}
      styles={customStyles}
      onChange={handleSelect}
      isSearchable={isSearchable}
      menuPortalTarget={document.body}
      isDisabled={isDisabled}
    />
  );
};

export default ReactSelect;
