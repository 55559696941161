import { subscribe } from "./pushHelper";

export const registerServiceWorker = async (userId) => {
  if ("serviceWorker" in navigator) {
    try {
      const reg = await navigator.serviceWorker.register("/service-worker.js");
      if (userId) {
        const subscription = await reg.pushManager.getSubscription();
        if (!subscription) {
          await subscribe(reg, userId);
        }
      }
    } catch (error) {
      console.error("Service worker registration failed:", error);
    }
  }
};
