import axios from "../../axios";

const isUserRegistered = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/check-registration`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const loginWithEmail = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/login`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const signUpWithEmail = async (values) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/signup`,
      data: values,
    });
  } catch (error) {
    return error.response;
  }
};

const sendOtp = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/otp/send`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const resendOtp = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/otp/resend`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const verifyOtp = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/otp/verify`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const logout = async () => {
  try {
    return await axios({
      method: "delete",
      url: `/api/user/logout`,
    });
  } catch (error) {
    return error.response;
  }
};

const forgotPassword = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/forgot-password`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const resetPassword = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/change-password`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const userCheckIfExists = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/check`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};

const loginWithOtp = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `/api/user/login-with-otp`,
      data,
    });
  } catch (error) {
    return error.response;
  }
};
const checkUserActive = async (userId) => {
  try {
    return await axios({
      method: "get",
      url: `/api/user/${userId}/active`,
    });
  } catch (error) {
    return error.response;
  }
};

export {
  checkUserActive,
  forgotPassword,
  isUserRegistered,
  loginWithEmail,
  loginWithOtp,
  logout,
  resendOtp,
  resetPassword,
  sendOtp,
  signUpWithEmail,
  userCheckIfExists,
  verifyOtp,
};
