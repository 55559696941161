import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import competeQuizIcon from "../../../assets/images/competeQuiz.png";
import playGameIcon from "../../../assets/images/games/mathmaster/playGame.png";
import practiceQuizIcon from "../../../assets/images/practiceQuiz.png";
import CommonButton from "../../Buttons/CommonButton";

const QuizTypeSelection = ({ isOpen, onClose }) => {
  return (
    <Modal
      focusTrapped={false}
      open={isOpen}
      onClose={onClose}
      showCloseIcon={false}
      classNames={{ modal: "thankyouModal" }}
      center
    >
      <div className="relative flex flex-col  items-center justify-center h-full  w-full">
        <div className="flex flex-col items-center justify-center">
          <h2 className="py-1.5  bg-[#760A98] text-white text-lg font-medium text-center w-full">
            - Play Now -
          </h2>

          <div className="border-b border-gray-500 pb-5 mt-8 mb-2 px-6 grid grid-cols-2 w-full gap-12">
            <Link
              onClick={onClose}
              to={"/quizzes/compete"}
              className="col-span-2 flex flex-col items-center justify-center"
            >
              <img
                src={competeQuizIcon}
                className="h-[66px] animate-scale"
                alt="performance"
              />
              <p className="mt-2 rounded-full font-semibold text-sm">
                Compete Quiz
              </p>
            </Link>
            <Link
              onClick={onClose}
              to={"/quizzes/practice"}
              className="flex flex-col items-center justify-center"
            >
              <img src={practiceQuizIcon} className="h-16" alt="performance" />
              <p className="mt-2 rounded-full font-semibold text-sm">
                Practice Quiz
              </p>
            </Link>

            <Link
              onClick={onClose}
              to={"/games/maths/mathmaster"}
              className="flex flex-col items-center justify-center"
            >
              <img src={playGameIcon} className="h-14" alt="performance" />
              <p className="mt-2 rounded-full font-semibold text-sm">
                MathMaster
              </p>
            </Link>
          </div>
        </div>

        <div className="mt-1 mb-3">
          <CommonButton
            classname={
              "py-2 border border-[#760A98] border-[3px] text-black font-bold hover:bg-red-400 hover:text-white"
            }
            text={"Close"}
            clickHandler={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};
export default QuizTypeSelection;
