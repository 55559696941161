import html2canvas from "html2canvas";
import React from "react";
import toast from "react-hot-toast";
import shareImage from "../../assets/images/share2.png";

const ShareButton = ({
  isQuizShare,
  innerRef,
  file,
  fileName,
  title,
  text,
  url,
  setLoading,
}) => {
  const handleShare = async () => {
    setLoading(true);

    try {
      if (file) {
        const response = await fetch(file);
        const blob = await response.blob();
        const fetchedFile = new File([blob], fileName, { type: blob.type });
        setLoading(false);

        if (navigator.share && navigator.canShare({ files: [fetchedFile] })) {
          await navigator
            .share({
              title: title,
              text: text,
              url: url,
              files: [fetchedFile],
            })
            .then(() => {})
            .catch((error) => {});
        } else {
          toast.error("Your system does not support file sharing.");
        }
      } else if (innerRef?.current) {
        const style = document.createElement("style");
        document.head.appendChild(style);
        style.sheet?.insertRule(
          "body > div:last-child img { display: inline-block; }"
        );
        style.sheet?.insertRule(
          "body > div:last-child img { line-height: initial; }"
        );
        style.sheet?.insertRule(
          "body > div:last-child span{ text-decoration: none; }"
        );

        const canvas = await html2canvas(innerRef.current, {
          useCORS: true,
          x: 0,
          y: 0,
        });

        style.remove();

        canvas.toBlob(async (blob) => {
          const screenshotFile = new File([blob], `${fileName}`, {
            type: "image/png",
          });
          setLoading(false);

          if (navigator.share) {
            await navigator
              .share({
                title: title,
                text: text,
                url: url,
                files: [screenshotFile],
              })
              .then(() => {})
              .catch((error) => {});
          } else {
            toast.error("Your system does not support file sharing.");
          }
        });
      } else {
        if (navigator.share) {
          await navigator
            .share({
              title: title,
              text: text,
              url: url,
            })
            .then(() => {})
            .catch((error) => {});
        } else {
          toast.error("Your system does not support sharing.");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <img
      src={shareImage}
      className={
        isQuizShare
          ? "absolute right-0 cursor-pointer w-10 h-10 rounded-full border border-[#760A98] bg-white border-[2px]"
          : "cursor-pointer w-12 h-12 rounded-full border border-[#760A98] bg-white border-[2px]"
      }
      alt="share"
      onClick={handleShare}
    />
  );
};

export default ShareButton;
