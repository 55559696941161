import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const audioRef = useRef(null);
  const [src, setSrc] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const location = useLocation();
  const [userPaused, setUserPaused] = useState(
    JSON.parse(localStorage.getItem("musicOff") || false)
  );

  const setUserPausedToLocalStorage = (status) => {
    localStorage.setItem("musicOff", status);
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (!isFirstRender) {
      audioRef.current.pause();
    }
  }, [location]);

  useEffect(() => {
    if (audioRef.current && src) {
      audioRef.current.src = src;
    }
  }, [src]);

  const play = (once = false, shallow = false) => {
    if (audioRef.current) {
      audioRef.current.loop = !once;
      const playPromise = audioRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            if (!shallow && !once) {
              setIsPlaying(true);
              setUserPaused(false);
              setUserPausedToLocalStorage(false);
            }
          })
          .catch((error) => {
            if (
              error.name === "NotAllowedError" ||
              error.name === "NotSupportedError"
            ) {
              console.log("Playback blocked: ", error.message);
            } else {
              console.log("Audio play failed: ", error.message);
            }
          });
      }
    }
  };

  const pause = (shallow = false) => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      if (!shallow) {
        setUserPaused(true);

        setUserPausedToLocalStorage(true);
      }
    }
  };

  const load = () => {
    return new Promise((resolve, reject) => {
      if (audioRef.current) {
        audioRef.current.addEventListener("canplaythrough", resolve, {
          once: true,
        });
        audioRef.current.addEventListener("error", reject, { once: true });
        audioRef.current.load();
      } else {
        reject(new Error("Audio element not available"));
      }
    });
  };

  const setSource = (source) => {
    setSrc(source);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return (
    <AudioContext.Provider
      value={{ play, pause, setSource, load, isPlaying, src, userPaused }}
    >
      {children}
      <audio ref={audioRef} loop hidden>
        Your browser does not support the audio element.
      </audio>
    </AudioContext.Provider>
  );
};

export const useAudio = () => useContext(AudioContext);
