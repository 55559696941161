import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/slices/userSlice";

const BottombarLink = ({ link, setShowLoginModal, setShowQuizTypeModal }) => {
  const user = useSelector(selectUser);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLinkClick = (e) => {
    e.preventDefault();
    if (!user?._id && link.loginRequired) {
      return setShowLoginModal(true);
    }

    if (link.text === "Quiz") return setShowQuizTypeModal(true);

    navigate(link.path);
  };

  return (
    <a
      href={link.path}
      onClick={handleLinkClick}
      className={`text-xs vsm:text-sm md:text-lg cursor-pointer flex flex-col justify-center items-center gap-[7px] md:gap-[8px] ${
        pathname === link.path ? "text-[#F0EF2A]" : ""
      }`}
    >
      {link.icon}
      {link.text}
    </a>
  );
};

export default BottombarLink;
