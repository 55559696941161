const installPWA = () => {
  const deferredPrompt = window.deferredPrompt;

  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then(() => {
      window.deferredPrompt = null;
    });
  }
};

export default installPWA;
