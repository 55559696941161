import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "react-responsive-modal/styles.css";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/video-react/dist/video-react.css";
import App from "./App";
import { AudioProvider } from "./contexts/AudioContext";
import { AuthProvider } from "./pages/Login/contexts/AuthContext";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <BrowserRouter>
        <AudioProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </AudioProvider>
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
);

reportWebVitals();
